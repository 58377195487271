import { createAsyncAction } from 'typesafe-actions';
import { IStatusBoard } from '../../service/StatusBoard';
import { AxiosError } from 'axios';

export const GET_STATUSBOARD = 'status-board/GET_STATUSBOARD';
export const GET_STATUSBOARD_SUCCESS = 'status-board/GET_STATUSBOARD_SUCCESS';
export const GET_STATUSBOARD_ERROR = 'status-board/GET_STATUSBOARD_ERROR';

export const getStatusBoardAsync = createAsyncAction(
    GET_STATUSBOARD,
    GET_STATUSBOARD_SUCCESS,
    GET_STATUSBOARD_ERROR
)<undefined, IStatusBoard, AxiosError>();