import { createReducer } from 'typesafe-actions';
import { StatusBoardState, StatusBoardAction } from './types';
import { GET_STATUSBOARD, GET_STATUSBOARD_SUCCESS, GET_STATUSBOARD_ERROR } from './actions';

const initialState: StatusBoardState = {
    getStatusBoard: {
        statusBoardLoading: false,
        statusBoardError: null,
        statusBoardData: null
    }
};

const statusBoard = createReducer<StatusBoardState, StatusBoardAction>(initialState, {
    [GET_STATUSBOARD]: state => ({
        ...state,
        getStatusBoard: {
            statusBoardLoading: true,
            statusBoardError: null,
            statusBoardData: null
        }
    }),
    [GET_STATUSBOARD_SUCCESS]: (state, action) => ({
        ...state,
        getStatusBoard: {
            statusBoardLoading: false,
            statusBoardError: null,
            statusBoardData: action.payload
        }
    }),
    [GET_STATUSBOARD_ERROR]: (state, action) => ({
        ...state,
        getStatusBoard: {
            statusBoardLoading: false,
            statusBoardError: action.payload,
            statusBoardData: null
        }
    })
});

export default statusBoard;