import {
    Container,
    Box,
    CircularProgress,
    Button
} from "@mui/material"
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getConsultResultByIdThunk } from '../../modules/consultResult';
import { getMoneyUnit } from "../../utils/MoneyUnit";
import Grid from "@mui/material/Unstable_Grid2";
import { kakaoChatStart } from '../../components/KakaoChat/KakaoChat';

const Result = () => {
    const { consultResultData, consultResultError } = useSelector((state: RootState) => state.consultResult.consultResult);
    const dispatch = useDispatch();
    const { resultId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        resultId ? dispatch(getConsultResultByIdThunk(resultId)) : navigate('/company-type');
    }, []);

    useEffect(() => {
        if (consultResultError) {
            alert('서버 에러가 발생했습니다.');
            navigate('/company-type');
        }
    }, [consultResultData, consultResultError]);

    const calcResultSupport24 = () => !consultResultData ? 0 : consultResultData.resultTax * 0.8 * -1;
    const minusCalcResultSupport24 = () => !consultResultData ? 0 : consultResultData.resultTax * 0.2;
    const sumSupportForTwoYears = () => !consultResultData ? 0 : consultResultData.supportMoneyCurrentYear + consultResultData.supportMoneyNextYear;
    const resultSupport24OnYears = () => sumSupportForTwoYears() + minusCalcResultSupport24() + calcResultSupport24() - 2400000;

    return <>
        {consultResultData ?
            <Container>
                <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
                    <img src="/images/Support24-Result.jpeg" style={{ width: '200px' }} alt={"지원24 이미지"} />
                </Box>
                <Grid container spacing={6}>
                    <Grid xs={0} sm={1} md={2}></Grid>
                    <Grid xs={12} sm={10} md={4}>
                        <Box sx={{ backgroundColor: '#3F3939', borderRadius: '15px' }}>
                            <Box sx={{ color: 'white', textAlign: 'center', fontSize: '23px', padding: '10px' }}>
                                2023년 산출 세액
                            </Box>
                            <Box sx={{ backgroundColor: '#F2F2F2', borderTopRightRadius: '20px', textAlign: 'right', padding: '30px', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                                <Box sx={{ fontSize: '16px' }}>{consultResultData.companyType === '개인' ? '종합소득세(개인)' : '법인세'}</Box>
                                <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px' }}><span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span>{getMoneyUnit(consultResultData.resultTax)}</Box>
                                <Box sx={{ marginTop: '30px' }}>
                                    <Box sx={{ fontSize: '16px' }}>지원24 산출세금</Box>
                                    <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: calcResultSupport24() > 0 ? '#117EF8' : '#F2006E' }}>{getMoneyUnit(calcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                </Box>
                                <Box sx={{ marginTop: '30px', borderTop: '1px solid black', paddingTop: '30px' }}>
                                    <Box sx={{ fontSize: '16px' }}>세금 절감액</Box>
                                    <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: minusCalcResultSupport24() > 0 ? '#117EF8' : '#F2006E' }}>{minusCalcResultSupport24() > 0 && '+'}{getMoneyUnit(minusCalcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={10} md={4} smOffset={1} mdOffset={0}>
                        <Box sx={{ backgroundColor: '#3F3939', borderRadius: '15px' }}>
                            <Box sx={{ color: 'white', textAlign: 'center', fontSize: '23px', padding: '10px' }}>
                                지원금 가능 금액
                            </Box>
                            <Box sx={{ backgroundColor: '#F2F2F2', borderTopLeftRadius: '20px', textAlign: 'right', padding: '30px', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                                <Box sx={{ fontSize: '16px' }}>2023년 현시점 지원금</Box>
                                <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: '#117EF8' }}>+{getMoneyUnit(consultResultData.supportMoneyCurrentYear)}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                <Box sx={{ marginTop: '30px' }}>
                                    <Box sx={{ fontSize: '16px' }}>2023년 추가 지원금</Box>
                                    <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: '#117EF8' }}>+{getMoneyUnit(consultResultData.supportMoneyNextYear)}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                </Box>
                                <Box sx={{ marginTop: '30px', borderTop: '1px solid black', paddingTop: '30px' }}>
                                    <Box sx={{ fontSize: '16px' }}>지원금 합산액</Box>
                                    <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: '#117EF8' }}>+{getMoneyUnit(sumSupportForTwoYears())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={0} sm={0} md={2}></Grid>
                </Grid>
                <Grid container sx={{ marginTop: '30px' }}>
                    <Grid xs={0} sm={1} md={2}></Grid>
                    <Grid xs={12} sm={10} md={8}>
                        <Box sx={{ backgroundColor: '#3F3939', margin: '0 auto', borderRadius: '15px' }}>
                            <Box sx={{ color: 'white', textAlign: 'center', fontSize: '23px', padding: '10px' }}>
                                12개월 x 월20만원 구독방식
                            </Box>
                            <Box sx={{ backgroundColor: '#F2F2F2', borderTopRightRadius: '20px', borderTopLeftRadius: '20px', padding: '30px', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                                <Box sx={{ marginTop: '5px' }}>
                                    <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>{consultResultData.companyType === '개인' ? '종합소득세(개인)세금' : '법인세'}</Box>
                                    <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                        <Box sx={{ fontSize: '22px', fontWeight: 'bold', width: '100%', color: calcResultSupport24() > 0 ? '#117EF8' : '#F2006E' }}>{calcResultSupport24() > 0 && '+'}{getMoneyUnit(calcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: '5px' }}>
                                    <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>12개월 구독료</Box>
                                    <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                        <Box sx={{ fontSize: '22px', fontWeight: 'bold', color: '#F2006E' }}>-2,400,000<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: '5px' }}>
                                    <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>절감 세액</Box>
                                    <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                        <Box sx={{ fontSize: '22px', fontWeight: 'bold', color: '#117EF8' }}>+{getMoneyUnit(minusCalcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: '5px' }}>
                                    <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>향후 지원금액</Box>
                                    <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                        <Box sx={{ fontSize: '22px', fontWeight: 'bold', color: '#117EF8' }}>+{getMoneyUnit(sumSupportForTwoYears())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                    </Box>
                                </Box>
                                <Box sx={{ borderTop: '1px solid black', paddingTop: '30px', textAlign: 'center', width: '60%', margin: '30px auto 0 auto' }}>
                                    <Box sx={{ fontSize: '16px', backgroundColor: '#3F3939', display: 'inline-block', color: 'white', padding: '10px', borderRadius: '10px' }}>지원24 1년 이용 시 총 비용</Box>
                                    <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '10px', color: resultSupport24OnYears() > 0 ? '#117EF8' : '#F2006E' }}>{resultSupport24OnYears() > 0 && '+'}{getMoneyUnit(resultSupport24OnYears())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={0} md={2}></Grid>
                </Grid>
                <Box sx={{ textAlign: 'center', marginTop: '60px' }}>
                    <Button onClick={kakaoChatStart} variant="contained" color="kakaoButton">카톡으로 지원 시작하기</Button>
                </Box>

                <br /> <br /> <br /> <br /><br /><br /><br />
            </Container> : <Box sx={{ display: 'flex', justifyContent: 'center', marginY: '300px' }}>
                <CircularProgress />
            </Box>
        }
    </>
}
export default Result;