import {
    FormControl,
    Container,
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import TextInput from '../TextInput/TextInput';
import CompanyNumberInput from '../TextInput/CompanyNumberInput';
import DateInput from '../TextInput/DateInput';
import EmployeesCountInput from '../TextInput/EmployeesCountInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getBusinessTypeThunk } from '../../modules/businessType';
import { postSurveyThunk } from '../../modules/survey';
import { getCompanyType } from '../../service/CompanyType';
import { getMoneyUnitFromString } from "../../utils/MoneyUnit";
import { getUser } from '../../service/User';

const CorporateForm = () => {
    const { businessTypeData } = useSelector((state: RootState) => state.businessType.businessType);
    const { surveyData, surveyError } = useSelector((state: RootState) => state.survey.postSurvey);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [problems, setProblems] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [workArea, setWorkArea] = useState<string>('');
    const [businessNumber, setBusinessNumber] = useState<Array<string>>(['', '', '']);
    const [registrationDate, setRegistrationDate] = useState<string>('');
    const [employeesCount, setEmployeesCount] = useState<string>('');
    const [totalSales, setTotalSales] = useState<string>('');
    const [businessTypeId, setBusinessTypeId] = useState<string>('');
    const [isOverdue, setIsOverdue] = useState<boolean | undefined>(undefined);
    const [expectedEmployment, setExpectedEmployment] = useState<string>('');

    useEffect(() => {
        dispatch(getBusinessTypeThunk());
    }, []);

    useEffect(() => {
        if (surveyError) return alert('서버 에러가 발생했습니다.');
        if (surveyData) return navigate(`/result/${surveyData.consultResultId}`);
    }, [surveyData, surveyError])

    const changeBusinessnumber = (index: number, value: string) => {
        const temp = businessNumber.map((e: string, i: number) => {
            if (i === index) {
                if (i === 0 && value.length > 3) {
                    return e;
                }
                if (i === 1 && value.length > 2) {
                    return e;
                }
                if (i === 2 && value.length > 5) {
                    return e;
                }
                return value;
            }
            return e;
        })
        setBusinessNumber(temp);
    }

    const changeTotalSales = (value: string) => {
        setTotalSales(value);
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const keyCode = Number(event.keyCode);
        if ((keyCode < 47 && keyCode !== 8) || keyCode > 57) {
            if (keyCode >= 96 && keyCode <= 105) {
                return true;
            }
            return event.preventDefault();
        } else {
            return true;
        }
    }

    const createSurvey = () => {
        if (!getUser()) {
            alert('유저정보가 없습니다.');
            navigate('/company-type');
            return;
        }
        if (name === "") return alert('성함을 입력 해주세요.');
        if (companyName === "") return alert('회사명을 작성 해주세요.');
        if (workArea === "") return alert('근무지역을 작성 해주세요.');
        if (businessNumber[0].length < 3 || businessNumber[1].length < 2 || businessNumber[2].length < 5) return alert('사업자 번호를 모두 입력 해주세요.');
        if (registrationDate.length < 8) return alert('사업자 등록일을 선택 해주세요.');
        if (employeesCount === "") return alert('4대보험 가입 직원수를 입력 해주세요.');
        if (employeesCount === "0" || employeesCount === "1" || employeesCount === "2") return alert('직원수 2인 미만은 신청 불가입니다.');
        if (expectedEmployment === "") return alert('24년 예상 고용 직원수를 입력 해주세요.');
        if (totalSales === "") return alert('매출액을 입력 해주세요.');
        if (businessTypeId === "") return alert('직종을 선택 해주세요.');
        if (isOverdue === undefined) return alert('세금체납, 금융권 연체 여부를 선택 해주세요');

        dispatch(postSurveyThunk({
            problems: problems,
            name: name,
            companyName: companyName,
            companyType: getCompanyType(),
            workArea: workArea,
            phoneNumber: "",
            userId: getUser().id,
            businessTypeId: businessTypeId,
            businessNumber: businessNumber[0] + businessNumber[1] + businessNumber[2],
            registrationDate: registrationDate,
            employeesCount: Number(employeesCount),
            expectedEmployment: Number(expectedEmployment),
            totalSales: Number(totalSales.replaceAll(',', '')),
            isOverdue: isOverdue
        }));
    }

    return <>
        <Container>
            <Box sx={{ fontSize: '25px', textAlign: 'center', marginTop: 6 }}>법인 사업자</Box>
            <br /><br />
            <TextInput label='경영관련 애로사항을 말씀해주세요.' placeholder='' id='problems' value={problems} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setProblems(e.target.value) }}></TextInput>
            <TextInput label='성함' placeholder='' id='name' value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setName(e.target.value) }}></TextInput>
            <TextInput label='회사 이름은 무엇인가요?' placeholder='' id='companyName' value={companyName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCompanyName(e.target.value) }}></TextInput>
            <TextInput label='사무실 지역이 어디인가요?(예: 강남,잠실,인천, 하남 등)' placeholder='' id='workArea' value={workArea} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setWorkArea(e.target.value) }}></TextInput>
            <CompanyNumberInput label='사업자 번호 10자리 작성 해주세요' placeholder='' id='businessNumber' value={businessNumber} onChange={(index: number, e: React.ChangeEvent<HTMLInputElement>) => { changeBusinessnumber(index, e.target.value) }}></CompanyNumberInput>
            <DateInput label='사업자 등록일이 언제인가요?(예: 2015년 5월 4일)' setDate={setRegistrationDate} />
            <EmployeesCountInput label='4대보험에 가입 직원 수는 몇명인가요?(직원수 2인 미만 신청 불가)' placeholder='' id='employeesCount' value={employeesCount} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEmployeesCount(e.target.value) }}></EmployeesCountInput>
            <EmployeesCountInput label='23년 추가 고용 예상 인원' placeholder='' id='expectedEmployment' value={expectedEmployment} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setExpectedEmployment(e.target.value) }}></EmployeesCountInput>
            <TextInput label='연 매출액은 얼마인가요?(숫자만 입력하세요)' placeholder='' id='totalSales' value={totalSales ? getMoneyUnitFromString(totalSales) : ''} onKeyDown={keyDownHandler} onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeTotalSales((e.target.value))}></TextInput>
            <Box sx={{ marginY: 1, }}>직종을 선택해주세요</Box>
            <Box sx={{
                padding: 2, backgroundColor: '#fcfcfb',
                border: '1px solid #ced4da',
            }}>
                <FormControl variant="standard">
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            {businessTypeData && businessTypeData.map((businessType, index) => <FormControlLabel value={index} control={<Radio onClick={() => setBusinessTypeId(businessType.id)} sx={{ "&.Mui-checked": { color: "#2f6bf0" } }} />} label={businessType.businessName} />)}
                        </RadioGroup>
                    </FormControl>
                </FormControl >
            </Box>
            <Box sx={{ marginY: 1, }}>세금체납이나 금융권에 연체가 있나요?</Box>
            <Box sx={{
                padding: 2, backgroundColor: '#fcfcfb',
                border: '1px solid #ced4da',
            }}>
                <FormControl variant="standard">
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="y" control={<Radio onClick={() => setIsOverdue(true)} sx={{ "&.Mui-checked": { color: "#2f6bf0" } }} />} label="예" />
                            <FormControlLabel value="n" control={<Radio onClick={() => setIsOverdue(false)} sx={{ "&.Mui-checked": { color: "#2f6bf0" } }} />} label="아니요" />
                        </RadioGroup>
                    </FormControl>
                </FormControl >
            </Box>

            <FormControl fullWidth sx={{ textAlign: 'center', marginY: 4 }}>
                <Button variant="contained" onClick={createSurvey} sx={{ padding: 2 }}> 정부지원 조회 신청</Button>
            </FormControl>
        </Container>
    </ >
}

export default CorporateForm;