import { useEffect } from "react";

declare global {
    interface Window {
        Kakao: any;
    }
}

export default function KakaoChat() {

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        try {
            if (window.Kakao) {
                const kakao = window.Kakao;
                if (!kakao.isInitialized()) {
                    kakao.init("d31a7dce95fffaf7617b4d010f86135f");
                }
            }
            document.body.appendChild(script);
            document.body.removeChild(script);
        } catch (err) { }
    }, [])

    return null;
}

export const kakaoChatStart = () => {
    window.Kakao.Channel.chat({
        channelPublicId: '_zxhxbgb'
    });
}
