import { createAsyncAction } from 'typesafe-actions';
import { ISurvey } from '../../service/Survey';
import { AxiosError } from 'axios';

export const GET_SURVEYLIST = 'survey/GET_SURVEYLIST';
export const GET_SURVEYLIST_SUCCESS = 'survey/GET_SURVEYLIST_SUCCESS';
export const GET_SURVEYLIST_ERROR = 'survey/GET_SURVEYLIST_ERROR';

export const POST_SURVEY = 'survey/POST_SURVEY';
export const POST_SURVEY_SUCCESS = 'survey/POST_SURVEY_SUCCESS';
export const POST_SURVEY_ERROR = 'survey/POST_SURVEY_ERROR';

export const getSurveyListAsync = createAsyncAction(
    GET_SURVEYLIST,
    GET_SURVEYLIST_SUCCESS,
    GET_SURVEYLIST_ERROR
)<undefined, Array<ISurvey>, AxiosError>();

export const postSurveyAsync = createAsyncAction(
    POST_SURVEY,
    POST_SURVEY_SUCCESS,
    POST_SURVEY_ERROR
)<undefined, ISurvey, AxiosError>();

