import { createReducer } from 'typesafe-actions';
import { KakaoState, KakaoAction } from './types';
import { GET_KAKAO, GET_KAKAO_SUCCESS, GET_KAKAO_ERROR } from './actions';

const initialState: KakaoState = {
    kakao: {
        kakaoLoading: false,
        kakaoError: null,
        kakaoData: null
    }
};

const kakao = createReducer<KakaoState, KakaoAction>(initialState, {
    [GET_KAKAO]: state => ({
        ...state,
        kakao: {
            kakaoLoading: true,
            kakaoError: null,
            kakaoData: null
        }
    }),
    [GET_KAKAO_SUCCESS]: (state, action) => ({
        ...state,
        kakao: {
            kakaoLoading: false,
            kakaoError: null,
            kakaoData: action.payload
        }
    }),
    [GET_KAKAO_ERROR]: (state, action) => ({
        ...state,
        kakao: {
            kakaoLoading: false,
            kakaoError: action.payload,
            kakaoData: null
        }
    }),
});

export default kakao;