import {
    Container,
    Box,
    CircularProgress
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSurveyAdminById } from '../../service/Survey';
import { getUser } from '../../service/User';
import { ISurveyAdmin } from '../../service/Survey';
import { getMoneyUnit } from "../../utils/MoneyUnit";
import Grid from "@mui/material/Unstable_Grid2";

const AdminSurveyDetail = () => {
    const navigate = useNavigate();
    const { surveyId } = useParams();
    const [loading, setLoading] = useState<Boolean>(true);
    const [surveyAdmin, setSurveyAdmin] = useState<ISurveyAdmin | undefined>(undefined);

    useEffect(() => {
        if (getUser() && surveyId) {
            const surveyAdminRequest = { userId: getUser().id };
            getSurveyAdminById(surveyId, surveyAdminRequest)
                .then((res) => {
                    setLoading(false);
                    setSurveyAdmin(res);
                }).catch((e) => {
                    alert("어드민 계정으로 다시 시도해주세요.");
                    setLoading(false);
                    console.error('e', e);
                    navigate('/admin');
                })
        } else {
            navigate('/admin')
        }
    }, []);

    const calcResultSupport24 = () => !surveyAdmin?.consultResult ? 0 : surveyAdmin?.consultResult.resultTax * 0.8 * -1;
    const minusCalcResultSupport24 = () => !surveyAdmin?.consultResult ? 0 : surveyAdmin?.consultResult.resultTax * 0.2;
    const sumSupportForTwoYears = () => !surveyAdmin?.consultResult ? 0 : surveyAdmin?.consultResult.supportMoneyCurrentYear + surveyAdmin?.consultResult.supportMoneyNextYear;
    const resultSupport24OnYears = () => sumSupportForTwoYears() + minusCalcResultSupport24() + calcResultSupport24() - 2400000;


    return <>
        {loading ? <>
            <Box sx={{ textAlign: 'center', marginY: '200px' }}>
                <CircularProgress />
            </Box>
            <br /><br /><br /><br /><br /><br /><br /><br />
        </> : <>
            {surveyAdmin ? <>
                <Container>
                    <Box sx={{ fontSize: '25px', textAlign: 'center', marginTop: 6 }}>{surveyAdmin.companyType}</Box>
                    <br /><br />
                    <Box sx={{ marginY: 2 }}>경영관련 애로사항</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.problems}</Box>
                    <Box sx={{ marginY: 2 }}>성함</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.name}</Box>
                    <Box sx={{ marginY: 2 }}>회사 이름</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.companyName}</Box>
                    <Box sx={{ marginY: 2 }}>사무실 지역</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.workArea}</Box>
                    <Box sx={{ marginY: 2 }}>사업자 번호</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.businessNumber}</Box>
                    <Box sx={{ marginY: 2 }}>사업자 등록일</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.registrationDate}</Box>
                    <Box sx={{ marginY: 2 }}>4대보험에 가입 직원 수</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.employeesCount}</Box>
                    <Box sx={{ marginY: 2 }}>23년 추가 고용 예상 인원</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.expectedEmployment}</Box>
                    <Box sx={{ marginY: 2 }}>연 매출액</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{getMoneyUnit(surveyAdmin.totalSales)}</Box>
                    <Box sx={{ marginY: 2 }}>직종</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.businessType}</Box>
                    <Box sx={{ marginY: 2 }}>세금체납이나 금융권에 연체가 있나요?</Box>
                    <Box sx={{ padding: 2, backgroundColor: '#F4f4f4' }}>{surveyAdmin.isOverdue ? '에' : '아니요'}</Box>
                    <br /><br />
                    <Container>
                        <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
                            <img src="/images/Support24-Result.jpeg" style={{ width: '200px' }} alt={"지원24 이미지"} />
                        </Box>
                        <Grid container spacing={6}>
                            <Grid xs={0} sm={1} md={2}></Grid>
                            <Grid xs={12} sm={10} md={4}>
                                <Box sx={{ backgroundColor: '#3F3939', borderRadius: '15px' }}>
                                    <Box sx={{ color: 'white', textAlign: 'center', fontSize: '23px', padding: '10px' }}>
                                        2023년 산출 세액
                                    </Box>
                                    <Box sx={{ backgroundColor: '#F2F2F2', borderTopRightRadius: '20px', textAlign: 'right', padding: '30px', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                                        <Box sx={{ fontSize: '16px' }}>{surveyAdmin.consultResult.companyType === '개인' ? '종합소득세(개인)' : '법인세'}</Box>
                                        <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px' }}>{getMoneyUnit(surveyAdmin.consultResult.resultTax)}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        <Box sx={{ marginTop: '30px' }}>
                                            <Box sx={{ fontSize: '16px' }}>지원24 산출세금</Box>
                                            <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: calcResultSupport24() > 0 ? '#117EF8' : '#F2006E' }}>{getMoneyUnit(calcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        </Box>
                                        <Box sx={{ marginTop: '30px', borderTop: '1px solid black', paddingTop: '30px' }}>
                                            <Box sx={{ fontSize: '16px' }}>세금 절감액</Box>
                                            <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: minusCalcResultSupport24() > 0 ? '#117EF8' : '#F2006E' }}>{minusCalcResultSupport24() > 0 && '+'}{getMoneyUnit(minusCalcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={10} md={4} smOffset={1} mdOffset={0}>
                                <Box sx={{ backgroundColor: '#3F3939', borderRadius: '15px' }}>
                                    <Box sx={{ color: 'white', textAlign: 'center', fontSize: '23px', padding: '10px' }}>
                                        지원금 가능 금액
                                    </Box>
                                    <Box sx={{ backgroundColor: '#F2F2F2', borderTopLeftRadius: '20px', textAlign: 'right', padding: '30px', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                                        <Box sx={{ fontSize: '16px' }}>2023년 현시점 지원금</Box>
                                        <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: '#117EF8' }}>+{getMoneyUnit(surveyAdmin.consultResult.supportMoneyCurrentYear)}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        <Box sx={{ marginTop: '30px' }}>
                                            <Box sx={{ fontSize: '16px' }}>2023년 추가 지원금</Box>
                                            <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: '#117EF8' }}>+{getMoneyUnit(surveyAdmin.consultResult.supportMoneyNextYear)}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        </Box>
                                        <Box sx={{ marginTop: '30px', borderTop: '1px solid black', paddingTop: '30px' }}>
                                            <Box sx={{ fontSize: '16px' }}>지원금 합산액</Box>
                                            <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '3px', color: '#117EF8' }}>+{getMoneyUnit(sumSupportForTwoYears())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs={0} sm={0} md={2}></Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: '30px' }}>
                            <Grid xs={0} sm={1} md={2}></Grid>
                            <Grid xs={12} sm={10} md={8}>
                                <Box sx={{ backgroundColor: '#3F3939', margin: '0 auto', borderRadius: '15px' }}>
                                    <Box sx={{ color: 'white', textAlign: 'center', fontSize: '23px', padding: '10px' }}>
                                        12개월 x 월20만원 구독방식
                                    </Box>
                                    <Box sx={{ backgroundColor: '#F2F2F2', borderTopRightRadius: '20px', borderTopLeftRadius: '20px', padding: '30px', borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
                                        <Box sx={{ marginTop: '5px' }}>
                                            <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>{surveyAdmin.consultResult.companyType === '개인' ? '종합소득세(개인)세금' : '법인세'}</Box>
                                            <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                                <Box sx={{ fontSize: '22px', fontWeight: 'bold', width: '100%', color: calcResultSupport24() > 0 ? '#117EF8' : '#F2006E' }}>{calcResultSupport24() > 0 && '+'}{getMoneyUnit(calcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ marginTop: '5px' }}>
                                            <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>12개월 구독료</Box>
                                            <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                                <Box sx={{ fontSize: '22px', fontWeight: 'bold', color: '#F2006E' }}>-2,400,000<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ marginTop: '5px' }}>
                                            <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>절감 세액</Box>
                                            <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                                <Box sx={{ fontSize: '22px', fontWeight: 'bold', color: '#117EF8' }}>+{getMoneyUnit(minusCalcResultSupport24())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ marginTop: '5px' }}>
                                            <Box sx={{ width: '45%', display: 'inline-block', textAlign: 'right' }}>향후 지원금액</Box>
                                            <Box sx={{ width: '35%', display: 'inline-block', textAlign: 'right', paddingRight: '20%' }}>
                                                <Box sx={{ fontSize: '22px', fontWeight: 'bold', color: '#117EF8' }}>+{getMoneyUnit(sumSupportForTwoYears())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ borderTop: '1px solid black', paddingTop: '30px', textAlign: 'center', width: '60%', margin: '30px auto 0 auto' }}>
                                            <Box sx={{ fontSize: '16px', backgroundColor: '#3F3939', display: 'inline-block', color: 'white', padding: '10px', borderRadius: '10px' }}>지원24 1년 이용 시 총 비용</Box>
                                            <Box sx={{ fontSize: '22px', fontWeight: 'bold', marginTop: '10px', color: resultSupport24OnYears() > 0 ? '#117EF8' : '#F2006E' }}>{resultSupport24OnYears() > 0 && '+'}{getMoneyUnit(resultSupport24OnYears())}<span style={{ fontSize: '20px', marginLeft: '7px' }}>₩</span></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid xs={0} md={2}></Grid>
                        </Grid>
                        <br /> <br /> <br /> <br /><br /><br /><br />
                    </Container>
                </Container>
            </> : <>
                <Box>데이터가 없습니다.</Box>
            </>}

        </>}

    </>
}

export default AdminSurveyDetail;