import api from './Api';

export async function getBusinessTypeList() {
    const response = await api.get<Array<IBusinessType>>('/business-type');
    return response.data;
}

export interface IBusinessType {
    id: string;
    businessName: number;
    taxRate: number;
}

