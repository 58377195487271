import api from '../../Api';
import { setUser } from '../../User';

export async function getKakao(code: string) {
    const response = await api.get<IUser>(`/oauth/kakao/${code}`);
    setUser(response.data);
    return response.data;
}

export async function getAdminKakao(code: string) {
    const response = await api.get<IUser>(`/oauth/admin/kakao/${code}`);
    setUser(response.data);
    return response.data;
}
