import { useRoutes } from 'react-router-dom';
import DefaultLayout from './components/Layout/DefaultLayout/DefaultLayout';
import Home from './pages/Home/Home';
import CompanyType from './pages/CompanyType/CompanyType';
import Result from './pages/Result/Result';
import Survey from './pages/Survey/Survey';
import Review from './pages/Review/Review';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import Admin from './pages/Admin/Admin';
import AdminServey from './pages/Admin/AdminSurvey';
import AdminSurveyDetail from './pages/Admin/AdminSurveyDetail';

export interface IRoute {
    path?: string;
    element?: React.ReactNode | null;
    children?: React.ReactNode;
}

const Routes = () => {
    return useRoutes([
        {
            path: '/',
            element: <DefaultLayout />,
            children: [
                { path: '', element: <Home /> },
                { path: 'company-type', element: <CompanyType /> },
                { path: 'survey', element: <Survey /> },
                { path: 'result/:resultId', element: <Result /> },
                { path: 'review', element: <Review /> },
                { path: '/terms', element: <Terms /> },
                { path: '/privacy', element: <Privacy /> },
                { path: 'admin', element: <Admin /> },
                { path: 'admin/survey', element: <AdminServey /> },
                { path: 'admin/survey/:surveyId', element: <AdminSurveyDetail /> },
            ]
        }
    ]);
}

export default Routes;