import { createReducer } from 'typesafe-actions';
import { SurveyState, SurveyAction } from './types';
import { GET_SURVEYLIST, GET_SURVEYLIST_SUCCESS, GET_SURVEYLIST_ERROR, POST_SURVEY, POST_SURVEY_SUCCESS, POST_SURVEY_ERROR } from './actions';

const initialState: SurveyState = {
    getSurveyList: {
        surveyLoading: false,
        surveyError: null,
        surveyData: null
    },
    postSurvey: {
        surveyLoading: false,
        surveyError: null,
        surveyData: null
    }
};

const survey = createReducer<SurveyState, SurveyAction>(initialState, {
    [GET_SURVEYLIST]: state => ({
        ...state,
        surveyList: {
            surveyLoading: true,
            surveyError: null,
            surveyData: null
        }
    }),
    [GET_SURVEYLIST_SUCCESS]: (state, action) => ({
        ...state,
        surveyList: {
            surveyLoading: false,
            surveyError: null,
            surveyData: action.payload
        }
    }),
    [GET_SURVEYLIST_ERROR]: (state, action) => ({
        ...state,
        surveyList: {
            surveyLoading: false,
            surveyError: action.payload,
            surveyData: null
        }
    }),
    [POST_SURVEY]: state => ({
        ...state,
        postSurvey: {
            surveyLoading: true,
            surveyError: null,
            surveyData: null
        }
    }),
    [POST_SURVEY_SUCCESS]: (state, action) => ({
        ...state,
        postSurvey: {
            surveyLoading: false,
            surveyError: null,
            surveyData: action.payload
        }
    }),
    [POST_SURVEY_ERROR]: (state, action) => ({
        ...state,
        postSurvey: {
            surveyLoading: false,
            surveyError: action.payload,
            surveyData: null
        }
    }),

});

export default survey;