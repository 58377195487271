import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_KAKAO = 'oauth/GET_KAKAO';
export const GET_KAKAO_SUCCESS = 'oauth/GET_KAKAO_SUCCESS';
export const GET_KAKAO_ERROR = 'oauth/GET_KAKAO_ERROR';

export const getKakaoAsync = createAsyncAction(
    GET_KAKAO,
    GET_KAKAO_SUCCESS,
    GET_KAKAO_ERROR
)<undefined, IUser, AxiosError>();

