import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { ConsultResultAction } from './types';
import { getConsultResultById } from '../../service/ConsultResult';
import { getConsultResultAsync } from './actions';

export function getConsultResultByIdThunk(id: string): ThunkAction<void, RootState, null, ConsultResultAction> {
    return async dispatch => {
        const { request, success, failure } = getConsultResultAsync;
        dispatch(request());
        try {
            const consultResult = await getConsultResultById(id);
            dispatch(success(consultResult));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};
