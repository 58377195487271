import {
    Box,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { ISurveyAdmin } from '../../service/Survey';
interface IProps {
    surveyList: Array<ISurveyAdmin>
}

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'Id',
        hide: true,

    },
    {
        field: 'name',
        headerName: '대표이름',
        width: 150,
        sortable: true,
    },
    {
        field: 'companyName',
        headerName: '회사이름',
        width: 150,
        sortable: true,
    },
    {
        field: 'businessNumber',
        headerName: '사업자번호',
        width: 110,
        sortable: true,
        valueFormatter: ({ value }) => `${value}`,
    },
    {
        field: 'email',
        headerName: '이메일',
        sortable: true,
        width: 160,
    },
    {
        field: 'phoneNumber',
        headerName: '전화번호',
        width: 110,
        sortable: true,
        valueFormatter: ({ value }) => `${value.replace('+82 ', '0')}`,
    },
    {
        field: 'businessType',
        headerName: '업종',
        width: 110,
        sortable: true,
    },
    {
        field: 'workArea',
        headerName: '지역',
        width: 110,
        sortable: true,
    },
    {
        field: 'registrationDate',
        headerName: '설문일자',
        width: 110,
        sortable: true,
        valueFormatter: ({ value }) => `${value}`,
    },
];



const AdminSurveyTable = (props: IProps) => {

    const navigator = useNavigate();

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        navigator(`/admin/survey/${params.row.id}`);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    console.log('props.surveyList', props.surveyList);

    return <Box sx={{ height: 600, width: '100%', marginY: 10 }}>
        <DataGrid
            columns={columns}
            rows={props.surveyList}
            onRowClick={handleRowClick}
            components={{
                Toolbar: CustomToolbar,
            }}
        />
    </Box>
}
export default AdminSurveyTable;