import {
    Box,
    Container
} from '@mui/material';

const Terms = () => {
    return <>
        <Container maxWidth="md" sx={{ marginY: { xs: 5, sm: 10 }, lineHeight: '30px' }}>
            <h1>서비스 이용약관</h1>
            <Box sx={{ borderTop: '1px solid #4394f0', borderBottom: '1px solid #ddd', padding: '10px 0', margin: '60px 0 30px 0', fontWeight: 'bold' }}>
                제1조 (목적)
                </Box>
            <Box>이 약관은 회원이 주식회사 청년들경영연구소(이하 '회사'라 합니다)가 제공하는 서비스(이하 "서비스"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무, 책임사항, 서비스 이용조건 및 절차 등 기본적인 사항을 정함으로써 상호간에 권리∙의무 관계를 명확히 하는 것을 목적으로 합니다.</Box>
            <Box sx={{ borderTop: '1px solid #4394f0', borderBottom: '1px solid #ddd', padding: '10px 0', margin: '60px 0 30px 0', fontWeight: 'bold' }}>
                제2조 (용어의 정의)
                </Box>
            <Box>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                    <ol>
                    <li>"회원"이란 본 약관에 따라 회원 가입신청에 대한 회사의 승낙을 받아회사가 제공하는 서비스를 이용하는 자를 말합니다. 온라인을 통해회원가입을 하고, 이 약관에 따라 회사와 이용계약을 체결하여 유무상으로서비스를 사용하는 고객을 말합니다.</li>
                    <li>"서비스"란 개인이 세금 및 경영지원 관련 업무를 모바일 및 PC 온라인 상으로 간편하게이용할 수 있도록 회사가 제공하는 일체의 서비스를 말합니다.</li>
                    <li>"이용료"라 함은 회사에서 제공하는 기본 서비스 및 부가서비스 이용료를말합니다.</li>
                    <li>"파트너세무사"라 함은 회원이 세무사를 이용하여 세금 납부/환급 관련업무를 진행하는 경우 해당 업무를 위임 받은 세무사들을 말합니다.</li>
                    <li>"수임동의"라 함은 회원이 파트너 세무사들에게 세무 관련 업무를 위임하는절차를 말합니다.</li>
                    <li>"(이용료) 환불" 이라 함은 회원이 결제했던 내역을 취소하는 것을말합니다.</li>
                    <li>"(세금) 환급" 이라 함은 회원이 신고를 통해 기납부한 세금에서 전부(또는일부)를 국가(국세청, 지방자치단체)로부터 돌려받는 것을 말합니다.</li>
                </ol>
                ② 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 관련 법령에 정한 바에 따릅니다.
                    </Box>
            <Box sx={{ borderTop: '1px solid #4394f0', borderBottom: '1px solid #ddd', padding: '10px 0', margin: '60px 0 30px 0', fontWeight: 'bold' }}>
                제3조 (약관의 게시, 해석, 변경 등)
                </Box>
            <Box>
                ① 회사는 회원이 서비스를 이용하기 전에 본 약관을 게시하고, 회원이 본 약관의 중요한 내용을 확인할 수 있도록 합니다.<br />
                ② 이 약관의 해석 또는 이 약관에서 정하지 않은 사항 등에 대하여는 관련 법령에 따릅니다. <br />
                ③ 회사는 이 약관의 내용을 변경하고자 하는 경우 변경사항의 시행 7일 전부터 회원에게 공지합니다. 다만, 이용자에게 불리하거나 중대한 사항의 변경은 최소한 30일 이전에 통지합니다. <br />
                ④ 회원은 변경된 약관이 공지된 후 15일 이내에 거부의사를 표명할 수 있습니다. 회원이 거부하는 경우 회사는 15일의 기간을 정하여 회원에게 사전 통지 후 당해 회원과의 계약을 해지할 수 있습니다. 만약, 회사가 개정약관 공지(통지)시 회원이 거부의사를 표시하지 않고 서비스를 계속 이용하는 경우 동의한 것으로 간주하겠다는 내용도 함께 공지(통지)하였음에도 회원이 거부의사를 표시하지 않고 서비스를 계속 이용하는 경우에는 개정약관에 동의하는 것으로 간주합니다.
                    </Box>
        </Container>
    </>
}

export default Terms;