import { createAsyncAction } from 'typesafe-actions';
import { IRegisterdUser } from '../../service/User';
import { AxiosError } from 'axios';

export const GET_REGISTERD_USER = 'user/GET_REGISTERD_USER';
export const GET_REGISTERD_USER_SUCCESS = 'user/GET_REGISTERD_USER_SUCCESS';
export const GET_REGISTERD_USER_ERROR = 'user/GET_REGISTERD_USER_ERROR';

export const getRegisterdUserListSurveyListAsync = createAsyncAction(
    GET_REGISTERD_USER,
    GET_REGISTERD_USER_SUCCESS,
    GET_REGISTERD_USER_ERROR
)<undefined, Array<IRegisterdUser>, AxiosError>();

