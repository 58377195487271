import Router from './routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import KakaoChat from './components/KakaoChat/KakaoChat';

const theme = createTheme({
  palette: {
    neutral: {
      main: '#d3d3d3',
      contrastText: '#a1a1a1',
    },
    kakaoButton: {
      main: '#ffef62',
      contrastText: '#000000',
    }
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    kakaoButton: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    kakaoButton?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    kakaoButton: true;
  }
}

function App() {

  return <ThemeProvider theme={theme}>
    <KakaoChat />
    <ScrollToTop />
    <Router />
  </ThemeProvider>
}

export default App;
