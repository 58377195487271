import {
    TextField,
    InputLabel,
    Box
} from '@mui/material';

import { Dayjs } from 'dayjs';
import 'dayjs/locale/ko';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { useState, useEffect } from 'react';

interface IProps {
    setDate: Function;
    label: string
}

const DateInput = ({ setDate, label }: IProps) => {
    const [value, setValue] = useState<Dayjs | null>(null);

    useEffect(() => {
        if (value == null) return;
        setDate(value?.format('YYYYMMDD'));
    }, [value]);

    const changeDate = (newValue: any) => {
        if (newValue && newValue != null) {
            setValue(newValue);
        }
    }

    return (
        <Box sx={{ marginY: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                {label !== '' && <InputLabel shrink htmlFor="bootstrap-input" sx={{ fontSize: '16px', color: 'black', transform: 'none', "&.Mui-focused": { color: "#2f6bf0" } }}>
                    {label}
                </InputLabel>}
                <Box sx={{ marginY: 1 }}>
                    <DatePicker
                        value={value}
                        onChange={(newValue) => { changeDate(newValue); }} renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
            </LocalizationProvider>
        </Box>
    )
}

export default DateInput;