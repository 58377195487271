import {
    Box,
    Container
} from '@mui/material';

const Privacy = () => {
    return <>
        <Container maxWidth="md" sx={{ marginY: { xs: 5, sm: 10 }, lineHeight: '30px' }}>
            <h1>개인정보 처리방침</h1>
            <Box>청년들경영연구소(이하 “회사”)는 “개인정보 보호법”, “전자상거래 등에서의 소비자보호에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련 법령에 따라 이용자의 개인정보를 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 개인정보처리방침에 따라 개인정보를 처리하고 있습니다.</Box>
            <Box sx={{ borderTop: '1px solid #4394f0', borderBottom: '1px solid #ddd', padding: '10px 0', margin: '60px 0 30px 0', fontWeight: 'bold' }}>
                1. 개인정보의 처리 목적
                </Box>
            <Box>회사는 서비스 이용에 필수적인 개인정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 처리 목적과 항목이 변경되는 경우에는 사전에 이용자의 동의를 받습니다.<br /><br />

                <h3>서비스 제공에 관한 계약이행 및 요금 정산</h3>
                <ol>
                    <li>종합소득세, 연말정산 등 각종 세금 관련 업무</li>
                    <li>파트너세무사의 세금신고 서비스 지원</li>
                    <li>제휴 세무사의 세무 상담을 위한 서비스 지원</li>
                    <li>정기·비정기 대금 자동 또는 수동 결제 등</li>
                </ol>
                <h3>회원 관리</h3>
                <ol>
                    <li>회원제 서비스 이용에 따른 본인 확인 및 법인·개인 식별</li>
                    <li>회원의 부정 이용 방지와 비인가 사용 방지, 가입의사 확인</li>
                    <li>불만 및 민원처리, 고지사항 전달, 광고성 정보 제공 및 참여기회 제공</li>
                    <li>이용기록 분석 및 통계분석 등</li>
                </ol>
            </Box>
            <Box sx={{ borderTop: '1px solid #4394f0', borderBottom: '1px solid #ddd', padding: '10px 0', margin: '60px 0 30px 0', fontWeight: 'bold' }}>
                2. 개인정보의 수집/처리 항목
                </Box>
            <Box>
                회사는 회원가입, 서비스의 이용, 고객상담, 세무상담 등의 각종 서비스 제공을 위해 아래와 같은 정보를 처리합니다.
                <ol>
                    <li>"회원"이란 본 약관에 따라 회원 가입신청에 대한 회사의 승낙을 받아회사가 제공하는 서비스를 이용하는 자를 말합니다. 온라인을 통해회원가입을 하고, 이 약관에 따라 회사와 이용계약을 체결하여 유무상으로서비스를 사용하는 고객을 말합니다.</li>
                    <li>"서비스"란 개인이 세금 관련 업무를 모바일 및 PC 온라인 상으로 간편하게이용할 수 있도록 회사가 제공하는 일체의 서비스를 말합니다.</li>
                    <li>"이용료"라 함은 회사에서 제공하는 기본 서비스 및 부가서비스 이용료를말합니다.</li>
                    <li>"파트너세무사"라 함은 회원이 세무사를 이용하여 세금 납부/환급 관련업무를 진행하는 경우 해당 업무를 위임 받은 세무사들을 말합니다.</li>
                    <li>"수임동의"라 함은 회원이 파트너 세무사들에게 세무 관련 업무를 위임하는절차를 말합니다.</li>
                    <li>"(이용료) 환불" 이라 함은 회원이 결제했던 내역을 취소하는 것을말합니다.</li>
                    <li>"(세금) 환급" 이라 함은 회원이 신고를 통해 기납부한 세금에서 전부(또는일부)를 국가(국세청, 지방자치단체)로부터 돌려받는 것을 말합니다.</li>
                </ol>
                    ② 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 관련 법령에 정한 바에 따릅니다.
            </Box>
        </Container>
    </>
}

export default Privacy;