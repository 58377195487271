import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import {
    Grid,
    Box,
    Container,
    Button,
    Stack,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import mainLogo from './main-logo.jpg';

const Header = () => {
    const navigate = useNavigate();
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setIsOpenDrawer(open);
        };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setIsOpenDrawer(false)}
            onKeyDown={() => setIsOpenDrawer(false)}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText primary={<Box sx={{ textAlign: 'right' }}>x</Box>} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'청년들 시스템'} disablePadding>
                    <ListItemButton onClick={() => window.open('https://www.watax.kr/', '_blank')}>
                        <ListItemText primary={'청년들 시스템'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'청년들 피플'} disablePadding>
                    <ListItemButton onClick={() => window.open('https://www.watax.kr/', '_blank')}>
                        <ListItemText primary={'청년들 피플'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'고객 리뷰'} disablePadding>
                    <ListItemButton onClick={() => navigate('/review')}>
                        <ListItemText primary={'고객 리뷰'} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'지원 24'} disablePadding>
                    <ListItemButton onClick={() => navigate('/')}>
                        <ListItemText primary={<Box sx={{ fontWeight: 'bold' }}>지원 24</Box>} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );


    return <>
        <Container maxWidth="lg" sx={{ marginY: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between" >
                <Grid sm={7}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={3}>
                        <Box><Link to="/"><img src={mainLogo} style={{ width: '180px' }} alt="main logo"></img></Link></Box>
                        <Box sx={{ display: { xs: 'none', lg: 'block' }, cursor: 'pointer' }} onClick={() => window.open('https://www.watax.kr/', '_blank')}>청년들 시스템</Box>
                        <Box sx={{ display: { xs: 'none', lg: 'block' }, cursor: 'pointer' }} onClick={() => window.open('https://www.watax.kr/', '_blank')}>청년들 피플</Box>
                        <Box sx={{ display: { xs: 'none', lg: 'block' }, cursor: 'pointer' }} onClick={() => navigate('/review')}>고객 리뷰</Box>
                        <Box sx={{ display: { xs: 'none', lg: 'block' }, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/')}>지원 24</Box>
                    </Stack>
                </Grid>
                <Grid sm={5}>
                    <Stack
                        direction="row"
                        spacing={4}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {/* <Box>공인인증센터</Box> */}
                        <Button sx={{ display: { md: 'block', lg: 'none' }, lineHeight: '13px' }} onClick={toggleDrawer(true)}><MenuIcon /></Button>

                    </Stack>

                </Grid>
            </Grid>

        </Container >

        <Drawer
            anchor={'right'}
            open={isOpenDrawer}
            onClose={toggleDrawer(false)}
        >
            {list()}
        </Drawer>
    </>
}

export default Header;