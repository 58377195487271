import React from 'react';
import CountUp from 'react-countup';
import {
    useScrollTrigger,
    Box,
    Grid,
    Container
} from '@mui/material';

interface IProps {
    dailyMemberCount: number;
    sumMemberCount: number;
    sumSupportMoney: number;
}

const StatusBoard = (props: IProps) => {
    const trigger = useScrollTrigger({ threshold: 0 });

    return (
        <>
            <Box sx={{ backgroundColor: 'rgb(249, 250, 252)', paddingY: 10, fontWeight: 'bold' }}>
                <Container maxWidth="lg">
                    <Box sx={{ fontSize: { xs: '27px', sm: '27px', md: '39px' }, textAlign: 'center', marginBottom: '60px' }}>행정업무 세금업무 지원금 혜택을<span style={{ color: '#2f6bf0' }}> 동시에</span></Box>
                    <Grid container sx={{ textAlign: 'center', color: '#2f6bf0', fontSize: { xs: '60px', sm: '80px' } }}>
                        {trigger ? <>
                            <Grid xs={12} sm={12} md={4} sx={{ height: { xs: '180px' } }}>
                                <CountUp start={0} end={Math.floor(props.dailyMemberCount)} duration={0.7} delay={0} /><span style={{ fontSize: 30 }}>명</span>
                                <Grid sx={{ color: '#a5a4a4', marginTop: '20px', fontSize: { xs: '21px', md: '23px' } }}>어제 가입자 수</Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={4} sx={{ height: { xs: '180px' } }}>
                                <CountUp start={0} end={Math.floor(props.sumMemberCount)} duration={0.7} delay={0} /><span style={{ fontSize: 30 }}>명</span>
                                <Grid sx={{ color: '#a5a4a4', marginTop: '20px', fontSize: { xs: '21px', md: '23px' } }}>누적 가입자 수</Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={4} sx={{ height: { xs: '180px' } }}>
                                <CountUp start={0} end={Math.floor(props.sumSupportMoney / 100000000)} duration={0.7} delay={0} /><span style={{ fontSize: 30 }}>억</span>
                                <Grid sx={{ color: '#a5a4a4', marginTop: '20px', fontSize: { xs: '21px', md: '23px' } }}>지원금 누적액</Grid>
                            </Grid>
                        </>
                            :
                            <>
                                <Grid xs={12} sm={12} md={4} sx={{ height: { xs: '180px' } }}>
                                    {Math.floor(props.dailyMemberCount)}<span style={{ fontSize: 30 }}>명</span>
                                    <Grid sx={{ color: '#a5a4a4', marginTop: '20px', fontSize: { xs: '21px', md: '23px' } }}>어제 가입자 수</Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={4} sx={{ height: { xs: '180px' } }}>
                                    {Math.floor(props.sumMemberCount)}<span style={{ fontSize: 30 }}>명</span>
                                    <Grid sx={{ color: '#a5a4a4', marginTop: '20px', fontSize: { xs: '21px', md: '23px' } }}>누적 가입자 수</Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={4} sx={{ height: { xs: '180px' } }}>
                                    {Math.floor(props.sumSupportMoney / 100000000)}<span style={{ fontSize: 30 }}>억</span>
                                    <Grid sx={{ color: '#a5a4a4', marginTop: '20px', fontSize: { xs: '21px', md: '23px' } }}>지원금 누적액</Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default StatusBoard;
