
import Header from '../Header/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';

export default function DefaultLayout() {



    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
}
