import {
    Container,
    Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getCompanyType } from '../../service/CompanyType';
import CorporateForm from '../../components/CorporateForm/CorporateForm';
import IndividualForm from '../../components/IndividualForm/IndividualForm';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getKakaoThunk } from '../../modules/oauth';

const Survey = () => {
    const navigate = useNavigate();
    const companyType = getCompanyType();
    const [searchParams] = useSearchParams();
    const { kakaoData, kakaoError } = useSelector((state: RootState) => state.kakao.kakao);
    const dispatch = useDispatch();
    const code = searchParams.get("code");

    useEffect(() => {
        if (!companyType || !code) {
            navigate('/company-type');
            return;
        }
        dispatch(getKakaoThunk(code));
    }, []);


    useEffect(() => {
        if (kakaoError) {
            alert('서버 에러가 발생했습니다.');
            navigate('/company-type');
        }
    }, [kakaoData, kakaoError]);

    return <>
        <Container>
            {companyType === '개인' ?
                <>
                    <IndividualForm></IndividualForm>
                </> :
                companyType === '법인' ?
                    <>
                        <CorporateForm></CorporateForm>
                    </> :
                    <><Box>회사 구분을 선택해주세요.</Box></>
            }
        </Container>
    </ >
}

export default Survey;