import api from './Api';

export async function getStatusBoard() {
    const response = await api.get<IStatusBoard>('/status-board');
    return response.data;
}

export interface IStatusBoard {
    dailyMemberCount: number;
    sumMemberCount: number;
    sumSupportMoney: number;
}