import { createReducer } from 'typesafe-actions';
import { RegisterdUserState, RegisterdUserAction } from './types';
import { GET_REGISTERD_USER, GET_REGISTERD_USER_SUCCESS, GET_REGISTERD_USER_ERROR } from './actions';

const initialState: RegisterdUserState = {
    registerdUser: {
        registerdUserLoading: false,
        registerdUserError: null,
        registerdUserData: null,
    }
};

const registerdUser = createReducer<RegisterdUserState, RegisterdUserAction>(initialState, {
    [GET_REGISTERD_USER]: state => ({
        ...state,
        registerdUser: {
            registerdUserLoading: true,
            registerdUserError: null,
            registerdUserData: null
        }
    }),
    [GET_REGISTERD_USER_SUCCESS]: (state, action) => ({
        ...state,
        registerdUser: {
            registerdUserLoading: false,
            registerdUserError: null,
            registerdUserData: action.payload
        }
    }),
    [GET_REGISTERD_USER_ERROR]: (state, action) => ({
        ...state,
        registerdUser: {
            registerdUserLoading: false,
            registerdUserError: action.payload,
            registerdUserData: null
        }
    }),
});

export default registerdUser;