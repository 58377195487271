import api from './Api';

export const getUser = () => {
    return JSON.parse(localStorage.getItem("watax-user") as string);
};

export const setUser = (user: IUser) => {
    localStorage.setItem("watax-user", JSON.stringify(user));
};

export const removeUser = () => {
    localStorage.removeItem("watax-user");
};



export async function getRegisterdUserListSurveyList() {
    const response = await api.get<Array<IRegisterdUser>>('/user/register');
    return response.data;
}


export interface IRegisterdUser {
    id: string;
    email: string;
    phoneNumber: string;
    name: string;
    createDate: string;
    businessType: string;

}