export const getCompanyType = () => {
    return JSON.parse(localStorage.getItem("company-type") as string);
};

export const setCompanyType = (companyType: string) => {
    localStorage.setItem("company-type", JSON.stringify(companyType));
};

export const removeCompanyType = () => {
    localStorage.removeItem("company-type");
};
