import { createAsyncAction } from 'typesafe-actions';
import { IBusinessType } from '../../service/BusinessType';
import { AxiosError } from 'axios';

export const GET_BUSINESSTYPE = 'BUSINESSTYPE/GET_BUSINESSTYPE';
export const GET_BUSINESSTYPE_SUCCESS = 'BUSINESSTYPE/GET_BUSINESSTYPE_SUCCESS';
export const GET_BUSINESSTYPE_ERROR = 'BUSINESSTYPE/GET_BUSINESSTYPE_ERROR';

export const getBusinessTypeAsync = createAsyncAction(
    GET_BUSINESSTYPE,
    GET_BUSINESSTYPE_SUCCESS,
    GET_BUSINESSTYPE_ERROR
)<undefined, Array<IBusinessType>, AxiosError>();

