import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { RegisterdUserAction } from './types';
import { getRegisterdUserListSurveyList } from '../../service/User';
import { getRegisterdUserListSurveyListAsync } from './actions';

export function getRegisterdUserListSurveyListThunk(): ThunkAction<void, RootState, null, RegisterdUserAction> {
    return async dispatch => {
        const { request, success, failure } = getRegisterdUserListSurveyListAsync;
        dispatch(request());
        try {
            const registerdUser = await getRegisterdUserListSurveyList();
            dispatch(success(registerdUser));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};
