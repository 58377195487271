import {
    Box,
    Container,
    Stack,
    Card,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getRegisterdUserListSurveyListThunk } from '../../modules/user';
import { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { getSecretName } from '../../utils/SecretName';

const Review = () => {
    const [selectedContents, setSelectedContents] = useState<number>(0);
    const { registerdUserData, registerdUserError } = useSelector((state: RootState) => state.user.registerdUser);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRegisterdUserListSurveyListThunk());
    }, []);

    useEffect(() => {
        if (registerdUserError) {
            alert('서버 에러가 발생했습니다.');
        }
    }, [registerdUserData, registerdUserError]);


    return <>
        <Container maxWidth="lg" sx={{ marginY: 10 }}>
            <Box sx={{ textAlign: 'center', fontSize: '28px' }}>소상공인 사장님은 왜 세무법인 청년들을 <b>좋아할까요 ?</b></Box>
            <Box sx={{ marginTop: '20px' }}>
                <Grid container spacing={2}>
                    <Grid xs={12} sm={6}>
                        <Box sx={{ textAlign: 'right' }}>
                            <Button
                                color={selectedContents === 0 ? "primary" : "neutral"}
                                variant="contained"
                                sx={{ width: { xs: '100%', sm: "300px" }, fontSize: '20px' }}
                                onClick={() => setSelectedContents(0)}
                            >
                                고객 이용 후기
                        </Button>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Box>
                            <Button
                                color={selectedContents === 1 ? "primary" : "neutral"}
                                variant="contained"
                                sx={{ width: { xs: '100%', sm: "300px" }, fontSize: '20px' }}
                                onClick={() => setSelectedContents(1)}
                            >
                                신규 고객 가입 현황
                        </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {selectedContents === 0 ? <>
                <Box sx={{ marginTop: '40px' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/001.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>의정부, 부산양대창</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>카드매출, 배달앱 매출을 확인할 수 있어서 좋아요.</Typography>
                                <Typography variant="body2" color="text.secondary">미리 확인하기 힘든 매출 내역을 한번에 확인할 수 있어 사업 자금관리에 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/002.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>마포 수수한헤어살롱</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>매출, 매입, 현금흐름 보고서로 관리하니까 순이익이 올랐어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매출, 매입에 대한 보고서로 사업에 많은 도움을 받고 있어요. 사업자금을 더 체계적으로 관리할 수 있어서 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/003.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>카페디에이</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>직원 출근 관리 시스템으로 월급 계산이 쉬워졌어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매번 바뀌는 직원 스케줄로 월급 계산하는게 일이였는데 이제 쉽게 관리할 수 있어요.</Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/004.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>의정부, 부산양대창</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>카드매출, 배달앱 매출을 확인할 수 있어서 좋아요.</Typography>
                                <Typography variant="body2" color="text.secondary">미리 확인하기 힘든 매출 내역을 한번에 확인할 수 있어 사업 자금관리에 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/005.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>마포 수수한헤어살롱</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>매출, 매입, 현금흐름 보고서로 관리하니까 순이익이 올랐어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매출, 매입에 대한 보고서로 사업에 많은 도움을 받고 있어요. 사업자금을 더 체계적으로 관리할 수 있어서 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/006.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>카페디에이</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>직원 출근 관리 시스템으로 월급 계산이 쉬워졌어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매번 바뀌는 직원 스케줄로 월급 계산하는게 일이였는데 이제 쉽게 관리할 수 있어요.</Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/010.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>의정부, 부산양대창</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>카드매출, 배달앱 매출을 확인할 수 있어서 좋아요.</Typography>
                                <Typography variant="body2" color="text.secondary">미리 확인하기 힘든 매출 내역을 한번에 확인할 수 있어 사업 자금관리에 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/011.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>마포 수수한헤어살롱</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>매출, 매입, 현금흐름 보고서로 관리하니까 순이익이 올랐어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매출, 매입에 대한 보고서로 사업에 많은 도움을 받고 있어요. 사업자금을 더 체계적으로 관리할 수 있어서 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/012.png"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>카페디에이</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>직원 출근 관리 시스템으로 월급 계산이 쉬워졌어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매번 바뀌는 직원 스케줄로 월급 계산하는게 일이였는데 이제 쉽게 관리할 수 있어요.</Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/20230106_114201_537.jpg"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>의정부, 부산양대창</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>카드매출, 배달앱 매출을 확인할 수 있어서 좋아요.</Typography>
                                <Typography variant="body2" color="text.secondary">미리 확인하기 힘든 매출 내역을 한번에 확인할 수 있어 사업 자금관리에 좋아요.</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: { xs: '100%', sm: '33%' } }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image="/images/review/20230106_114209_946.jpg"
                                title="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom component="div" sx={{ color: '#2f6bf0', fontSize: '13px', fontWeight: 'bold' }}>마포 수수한헤어살롱</Typography>
                                <Typography gutterBottom component="div" sx={{ fontWeight: 'bold' }}>매출, 매입, 현금흐름 보고서로 관리하니까 순이익이 올랐어요.</Typography>
                                <Typography variant="body2" color="text.secondary">매출, 매입에 대한 보고서로 사업에 많은 도움을 받고 있어요. 사업자금을 더 체계적으로 관리할 수 있어서 좋아요.</Typography>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
            </> : <>
                <Box sx={{ marginTop: '40px' }}>
                    <TableContainer component={Paper} sx={{ height: '655px', overflowY: 'scroll' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">가입일</TableCell>
                                    <TableCell align="center">이름</TableCell>
                                    <TableCell align="center">업종</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {registerdUserData && registerdUserData.map((registerdUser, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">{registerdUser.createDate.substring(0, 10)}</TableCell>
                                        <TableCell align="center">{getSecretName(registerdUser.name) + "님이 가입하셨습니다."}</TableCell>
                                        <TableCell align="center">{registerdUser.businessType}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>}


        </Container>
    </>
}

export default Review;