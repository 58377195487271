import { createReducer } from 'typesafe-actions';
import { BusinessTypeState, BusinessTypeAction } from './types';
import { GET_BUSINESSTYPE, GET_BUSINESSTYPE_SUCCESS, GET_BUSINESSTYPE_ERROR } from './actions';

const initialState: BusinessTypeState = {
    businessType: {
        businessTypeLoading: false,
        businessTypeError: null,
        businessTypeData: null
    }
};

const survey = createReducer<BusinessTypeState, BusinessTypeAction>(initialState, {
    [GET_BUSINESSTYPE]: state => ({
        ...state,
        businessType: {
            businessTypeLoading: true,
            businessTypeError: null,
            businessTypeData: null
        }
    }),
    [GET_BUSINESSTYPE_SUCCESS]: (state, action) => ({
        ...state,
        businessType: {
            businessTypeLoading: false,
            businessTypeError: null,
            businessTypeData: action.payload
        }
    }),
    [GET_BUSINESSTYPE_ERROR]: (state, action) => ({
        ...state,
        businessType: {
            businessTypeLoading: false,
            businessTypeError: action.payload,
            businessTypeData: null
        }
    }),
});

export default survey;