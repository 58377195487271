import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { KakaoAction } from './types';
import { getKakao, getAdminKakao } from '../../service/Oauth/Kakao/index';
import { getKakaoAsync } from './actions';

export function getKakaoThunk(code: string): ThunkAction<void, RootState, null, KakaoAction> {
    return async dispatch => {
        const { request, success, failure } = getKakaoAsync;
        dispatch(request());
        try {
            const kakao = await getKakao(code);
            dispatch(success(kakao));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};

export function getAdminKakaoThunk(code: string): ThunkAction<void, RootState, null, KakaoAction> {
    return async dispatch => {
        const { request, success, failure } = getKakaoAsync;
        dispatch(request());
        try {
            const kakao = await getAdminKakao(code);
            dispatch(success(kakao));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};
