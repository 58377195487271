import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { BusinessTypeAction } from './types';
import { getBusinessTypeList } from '../../service/BusinessType';
import { getBusinessTypeAsync } from './actions';

export function getBusinessTypeThunk(): ThunkAction<void, RootState, null, BusinessTypeAction> {
    return async dispatch => {
        const { request, success, failure } = getBusinessTypeAsync;
        dispatch(request());
        try {
            const businessTypeList = await getBusinessTypeList();
            dispatch(success(businessTypeList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};
