import {
    Box,
    CircularProgress,
    Container
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getAdminKakaoThunk } from '../../modules/oauth';
import { getSurveyAdminList, ISurveyAdmin } from '../../service/Survey';
import { getUser } from '../../service/User';
import AdminSurveyTable from '../../components/Table/AdminSurveyTable';

const AdminSurvey = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { kakaoData, kakaoError } = useSelector((state: RootState) => state.kakao.kakao);
    const [loading, setLoading] = useState<Boolean>(true);
    const [surveyList, setSurveyList] = useState<Array<ISurveyAdmin> | undefined>(undefined);
    const dispatch = useDispatch();
    const code = searchParams.get("code");

    useEffect(() => {
        if (!code) {
            navigate('/admin');
            return;
        }
        if (!getUser()) {
            dispatch(getAdminKakaoThunk(code));
        } else {
            const surveyAdminRequest = { userId: getUser().id };
            getSurveyAdminList(surveyAdminRequest).then((data) => {
                setSurveyList(data);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
                alert('어드민 유저가 아닙니다.');
                navigate('/');
            });
        }
    }, []);

    useEffect(() => {
        if (kakaoError) {
            setLoading(false);
            alert('서버 에러가 발생했습니다.');
            navigate('/admin');
        }

        if (kakaoData && getUser()) {
            const surveyAdminRequest = { userId: getUser().id };
            getSurveyAdminList(surveyAdminRequest).then((data) => {
                setSurveyList(data);
                setLoading(false);
            }).catch(() => {
                setLoading(false);
                alert('어드민 유저가 아닙니다.');
                navigate('/');
            });
        }

    }, [kakaoData, kakaoError]);

    return <Container maxWidth="lg">
        {loading ? <Box sx={{ textAlign: 'center', marginY: '30vh' }}>
            <CircularProgress />
        </Box> : <Box>
            {surveyList && <AdminSurveyTable surveyList={surveyList} />}
        </Box>}

    </Container>
}

export default AdminSurvey;