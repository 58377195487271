import { alpha, styled } from '@mui/material/styles';
import {
    InputBase,
    InputLabel,
    FormControl,
    Stack,
    Box
} from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '26px',
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fcfcfb',
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '100%',
        padding: '14px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha('#2f6bf0', 0.25)} 0 0 0 0.2rem`,
            borderColor: '#2f6bf0'
        },
    },
}));

interface IProps {
    label: string;
    placeholder: string;
    id: string;
    value: string;
    onChange: Function;
    readOnly?: boolean;
}

const EmployeesCountInput = ({ label, placeholder, id, value, onChange, readOnly = false }: IProps) => {
    return (
        <FormControl variant="standard" sx={{ width: '100%', marginY: 1 }}>
            {label !== '' && <InputLabel shrink htmlFor="bootstrap-input" sx={{ fontSize: '16px', color: 'black', transform: 'none', maxWidth: '100%', "&.Mui-focused": { color: "#2f6bf0" } }}>
                {label}
            </InputLabel>}
            <Stack direction="row" spacing={1} sx={{ marginTop: '26px', alignItems: 'center' }}>
                <Box sx={{ width: '30%' }}><BootstrapInput value={value} type={'number'} placeholder={placeholder} id={id} fullWidth onChange={(e) => onChange(e)} readOnly={readOnly} /></Box>
                <Box sx={{ width: '30%' }}>명</Box>
            </Stack>
        </FormControl >
    )
}

export default EmployeesCountInput;