import { createReducer } from 'typesafe-actions';
import { ConsultResultState, ConsultResultAction } from './types';
import { GET_CONSUITRESUIT, GET_CONSUITRESUIT_SUCCESS, GET_CONSUITRESUIT_ERROR } from './actions';

const initialState: ConsultResultState = {
    consultResult: {
        consultResultLoading: false,
        consultResultError: null,
        consultResultData: null
    }
};

const consultResult = createReducer<ConsultResultState, ConsultResultAction>(initialState, {
    [GET_CONSUITRESUIT]: state => ({
        ...state,
        consultResult: {
            consultResultLoading: true,
            consultResultError: null,
            consultResultData: null
        }
    }),
    [GET_CONSUITRESUIT_SUCCESS]: (state, action) => ({
        ...state,
        consultResult: {
            consultResultLoading: false,
            consultResultError: null,
            consultResultData: action.payload
        }
    }),
    [GET_CONSUITRESUIT_ERROR]: (state, action) => ({
        ...state,
        consultResult: {
            consultResultLoading: false,
            consultResultError: action.payload,
            consultResultData: null
        }
    }),
});

export default consultResult;