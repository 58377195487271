import api from './Api';
import { IConsultResult } from './ConsultResult';

export async function getSurveyList() {
    const response = await api.get<Array<ISurvey>>('/survey');
    return response.data;
}

export async function getSurveyAdminList(surveyAdminRequest: ISurveyAdminRequest) {
    const response = await api.post<Array<ISurveyAdmin>>('/survey/admin', surveyAdminRequest);
    return response.data;
}

export async function getSurveyAdminById(surveyId: string, surveyAdminRequest: ISurveyAdminRequest) {
    const response = await api.post<ISurveyAdmin>(`/survey/admin/${surveyId}`, surveyAdminRequest);
    return response.data;
}


export async function postSurvey(postSurvey: IPostSurvey) {
    console.log('postSurvey 서비스단 데이터 검증', postSurvey)
    const response = await api.post<ISurvey>('/survey', postSurvey);
    return response.data;
}

export interface ISurvey {
    id: string;
    consultResultId: string;
    problems: string;
    name: string;
    companyName: string;
    workArea: string;
    phoneNumber: string;
    businessType: string;
    businessNumber: string;
    registrationDate: string;
    employeesCount: number;
    expectedEmployment: number;
    totalSales: number;
    isOverdue: boolean;
}

export interface ISurveyAdmin {
    id: string;
    consultResult: IConsultResult;
    problems: string;
    name: string;
    companyName: string;
    companyType: string;
    workArea: string;
    email: string
    phoneNumber: string;
    businessType: string;
    businessNumber: string;
    registrationDate: string;
    employeesCount: number;
    expectedEmployment: number;
    totalSales: number;
    isOverdue: boolean;
}

export interface IPostSurvey {
    problems: string;
    name: string;
    companyName: string;
    companyType: string;
    workArea: string;
    phoneNumber: string;
    userId: string;
    businessTypeId: string;
    businessNumber: string;
    registrationDate: string;
    employeesCount: number;
    expectedEmployment: number;
    totalSales: number;
    isOverdue: boolean;
}

export interface ISurveyAdminRequest {
    userId: string
}