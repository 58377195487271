import {
    Box, Button,
} from '@mui/material';

const Admin = () => {
    return <>
        <Box sx={{ textAlign: 'center', marginY: '30vh', justifyContent: 'center' }}>
            <Button>
                <img
                    src={'kakao_login.png'}
                    style={{ width: '200px', padding: '10px' }}
                    onClick={() => {
                        window.location.href = 'https://kauth.kakao.com/oauth/authorize?client_id=3594f96dc79063c265a9325eda7b8988&redirect_uri=https://tax24app.com/admin/survey&response_type=code';
                    }}>
                </img>
            </Button>
        </Box>
    </>
}

export default Admin;