import {
    Grid,
    Box,
    Container,
    Button,
    Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getStatusBoardThunk } from '../../modules/statusBoard';
import StatusBoard from '../../components/StatusBoard/StatusBoard';

const Home = () => {
    const { statusBoardData } = useSelector((state: RootState) => state.statusBoard.getStatusBoard);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getStatusBoardThunk());
    }, []);
    const navigate = useNavigate();

    return <div>
        <Box
            sx={{
                background: 'url(main-image.png) no-repeat 20% 200px / cover',
                backgroundColor: 'lightblue',
                height: { xs: '429px', sm: '690px' }
            }}>

            <Box sx={{ fontWeight: 'bold', paddingTop: { xs: '70px', sm: '100px' }, marginLeft: { lg: '175px', sm: '100px', xs: '50px' } }}>
                <Box sx={{ fontSize: { xs: '17px', sm: '23px' } }}>7백만 중소기업 지원서비스</Box>
                <Stack direction="row" sx={{ fontSize: { xs: '28px', sm: '43px' }, lineHeight: { xs: '45px', sm: '67px' } }} >
                    <Box sx={{ color: '#2f6bf0' }}>세무, 지원금</Box>
                    <Box>을</Box>
                </Stack>
                <Stack direction="row" sx={{ fontSize: { xs: '28px', sm: '43px' }, lineHeight: { xs: '45px', sm: '67px' } }}>
                    <Box sx={{ color: '#2f6bf0' }}>청년들이</Box>
                    <Box>도와드립니다.</Box>
                </Stack>
                <Box sx={{ marginTop: '1rem' }}>
                    <Button onClick={() => navigate(`/company-type`)} variant="contained" size="large" sx={{ fontWeight: 'bold' }}>카톡으로 지원 시작하기</Button>
                </Box>
            </Box>
        </Box>

        {statusBoardData &&
            <StatusBoard dailyMemberCount={statusBoardData.dailyMemberCount} sumMemberCount={statusBoardData.sumMemberCount} sumSupportMoney={statusBoardData.sumSupportMoney} />
        }

        <Box>
            <img src="/images/Support24-Home.jpeg" style={{ width: '100%' }} alt="지원24 이미지" />
        </Box>

        <Box sx={{ paddingTop: 10 }}>
            <Container maxWidth="lg">
                <Box sx={{ fontSize: { xs: '27px', sm: '27px', md: '39px' }, textAlign: 'center', marginBottom: '60px', fontWeight: 'bold' }}>각종 불법, 피싱, 정보 유출에서 안전합니다</Box>
            </Container>

            <Container sx={{ maxWidth: { sm: '450px' }, margin: { sm: '0 auto' }, display: { md: 'none' } }}>
                <img data-at="image" alt="" src="main-group-image.jpg" style={{ width: '100%', height: '100%' }} />
                <Box sx={{ backgroundColor: 'white', paddingY: 5, textAlign: 'center' }}>
                    <Box sx={{ fontSize: { xs: '18.5px', sm: '1.4861rem' }, fontWeight: 'bold' }}>세무법인 청년들이 직접 만들고 사후관리까지!</Box>
                    <br />
                    <Box sx={{ fontSize: { xs: '15.7px', sm: '17px' }, lineHeight: '26px' }}>
                        <Box>전국 <strong>6개 지점</strong>을 보유한 대형 세무법인</Box>
                        <Box>경영지도사, 노무사 등 전문인력 협업인원 12명</Box>
                        <Box><strong>40명</strong>의 전문 세무인력</Box>
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="md" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <Grid container>
                    <Grid md={6}>
                        <img data-at="image" alt="" src="main-group-image.jpg" style={{ width: '100%', height: '100%' }} />
                    </Grid>
                    <Grid md={6} sx={{ backgroundColor: 'white' }}>
                        <Box sx={{ padding: 5 }}>

                            <Box sx={{ fontSize: '1.4861rem', fontWeight: 'bold' }}>행정,사후관리,세금신고 세무사들이 직접 관리합니다.</Box>
                            <br /> <Box>세무법인에서 <b>직접</b> 관리하고, 사후 <b>피드백</b>까지!</Box>
                            <br /><br />
                            <Box sx={{ fontSize: '0.9907rem' }}>
                                <Box><strong>전국 6개 </strong>지점을 보유한 대형 세무법인</Box>
                                <Box>경영지도사,노무사,변호사, 등 전문인력과</Box>
                                <Box><strong>40명</strong>의 전문 세무인력이 집중관리합니다.</Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <br /><br />
            </Container>
        </Box >

        <Container maxWidth="lg" sx={{ marginTop: { xs: 5, sm: 10 } }}>
            <Box sx={{ fontSize: { xs: '27px', sm: '27px', md: '39px' }, textAlign: 'center', marginBottom: { xs: '20px', sm: '60px' }, fontWeight: 'bold' }}>1년치 세무, 지원금 <span style={{ color: '#2f6bf0' }}>5분</span> 투자로 신청가능</Box>
        </Container>

        <Box sx={{ marginY: { xs: 0, sm: 10 }, marginBottom: { xs: 10, } }}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row', }} sx={{ alignItems: 'center' }}>
                <Grid
                    sx={{
                        background: 'url(images/SupportMony-home.jpeg) no-repeat 100% 100%/ contain',
                        backgroundPosition: { xs: 'center center', md: 'right' },
                        width: { xs: '70%', sm: '51%' },
                        height: { xs: '380px', sm: '450px' },
                        marginBottom: { xs: 0, sm: '30px' }
                    }}>
                </Grid>
                <Grid sx={{ marginTop: 20, fontWeight: 'bold', lineHeight: 1.8, paddingBottom: 10 }}>
                    <Stack direction={{ xs: "row" }}>
                        <Box sx={{ fontSize: { xs: '17px', sm: '21px' } }}>세무 대리인이</Box>
                    </Stack>

                    <Stack direction={{ xs: "row" }} sx={{ justifyContent: { xs: 'center', md: 'normal' } }}>
                        <Box sx={{ fontSize: { xs: '17px', sm: '21px' } }}>지원금과 진단까지</Box>
                    </Stack>

                    <Stack direction={{ xs: "row" }}>
                        <Box sx={{ color: '#2f6bf0', fontSize: { xs: '17px', sm: '21px' } }}>원스톱 서비스로</Box>
                    </Stack>
                    <Stack direction={{ xs: "row" }} sx={{ justifyContent: 'center', marginRight: 2 }}>
                        <Box sx={{ marginTop: '3rem' }}>
                            <Button onClick={() => navigate(`/company-type`)} variant="contained" size="large" sx={{ fontWeight: 'bold' }}>상담 시작하기</Button>
                        </Box>
                    </Stack>
                </Grid>
            </Stack>
        </Box>








    </div >
}

export default Home;