import { createAsyncAction } from 'typesafe-actions';
import { IConsultResult } from '../../service/ConsultResult';
import { AxiosError } from 'axios';

export const GET_CONSUITRESUIT = 'consultResult/GET_CONSUITRESUIT';
export const GET_CONSUITRESUIT_SUCCESS = 'consultResult/GET_CONSUITRESUIT_SUCCESS';
export const GET_CONSUITRESUIT_ERROR = 'consultResult/GET_CONSUITRESUIT_ERROR';

export const getConsultResultAsync = createAsyncAction(
    GET_CONSUITRESUIT,
    GET_CONSUITRESUIT_SUCCESS,
    GET_CONSUITRESUIT_ERROR
)<undefined, IConsultResult, AxiosError>();

