import {
    Box, Container, Typography, Stack
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';


export default function Footer() {
    const navigate = useNavigate();

    return (
        <Typography
            component="footer"
            sx={{ display: 'flex', bgcolor: '#fafafa' }}
        >
            {/* <Container sx={{ my: 6, display: 'flex' }}>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <Stack
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            sx={{ minWidth: '500px' }}
                        >
                            <Box sx={{ fontSize: '25px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/')}>세무법인청년들</Box>
                            <Box sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => window.open('https://www.watax.kr/', '_blank')}>회사소개</Box>
                            <Box sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => navigate('/terms')}> 이용약관</Box>
                            <Box sx={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/privacy')}>개인정보 처리방침</Box>
                            <Box></Box>
                            <Box></Box>
                            <Box></Box>
                        </Stack>
                    </Box>
                    <Box>
                        <Box sx={{ fontSize: '14px', width: '623px', color: '#999' }}>
                            삼쩜삼 | (주) 자비스앤빌런즈 | 대표자: 김범섭 | 개인정보보호책임자: 김춘추 | 사업자등록번호: 158-86-00171<br />
                            통신판매업신고번호: 2017-서울강남-02346 | 서울특별시 강남구 테헤란로 332, HJ타워 10층(역삼동)<br />
                            고객상담 전화번호 1688-7198 | 평일 10:30-18:00 | 점심 12:30-13:30 | 주말∙공휴일 휴무
                        </Box>
                    </Box>
                </Stack>
            </Container> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Container sx={{ my: 6 }}>
                <Grid container spacing={4}>
                    <Grid xs={12} md={7} lg={6}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={4} md={6} lg={5}>
                                    <Box sx={{ fontSize: '25px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/')}>세무법인청년들</Box>
                                </Grid>
                                <Grid xs={12} sm={8} md={6} lg={7}>
                                    <Stack
                                        direction="row"
                                        alignItems="flex-start"
                                        justifyContent="space-between"
                                    >

                                        <Box sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => window.open('https://www.watax.kr/', '_blank')}>회사소개</Box>
                                        <Box sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => navigate('/terms')}> 이용약관</Box>
                                        <Box sx={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/privacy')}>개인정보 처리방침</Box>
                                        <Box></Box>
                                    </Stack>
                                </Grid>

                            </Grid>

                        </Box>
                    </Grid>
                    <Grid xs={12} md={5} lg={6}>
                        <Box sx={{ fontSize: '14px', color: '#999' }}>
                            지원24 | 청년들경영연구소 | 대표자: 김재우 | 개인정보보호책임자: 염정희 | 사업자등록번호: 893-56-00490 통신판매업신고번호: 신고예정 | 경기도 화성시 동탄기흥로 560, 609호 고객상담 전화번호 1899-6419 | 평일 09:00-18:00 | 점심 12:00-13:00 | 주말∙공휴일 휴무
                        </Box>
                    </Grid>
                </Grid>


            </Container>
        </Typography >
    );
}