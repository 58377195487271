import api from './Api';

export async function getConsultResultById(id: string) {
    const response = await api.get<IConsultResult>(`/consult-result/${id}`);
    return response.data;
}

export interface IConsultResult {
    id: string;
    resultTax: number;
    supportMoneyCurrentYear: number;
    supportMoneyNextYear: number;
    companyType: string;
}