import {
    Box,
    Container,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { setCompanyType } from '../../service/CompanyType';
import { useState } from 'react';

const SelectEntity = () => {
    const [agreePrivacy, setAgreePrivacy] = useState<boolean>(false);


    const changeAgreePrivacy = () => {
        setAgreePrivacy(!agreePrivacy);
    }

    return <>
        <Container maxWidth="lg" sx={{ textAlign: 'center', height: '80vh', minHeight: '600px' }}>
            <Box sx={{ fontSize: '28px', marginTop: { xs: 10, sm: 20 }, marginBottom: { xs: 10, sm: 15 } }}>
                <Box>사업자 <span style={{ color: '#2f6bf0', fontWeight: 'bold' }}>44%</span>가</Box>
                <Box>평균 <span style={{ color: '#2f6bf0', fontWeight: 'bold' }}>13,200,000</span>원을 지원 받습니다.</Box>
            </Box>
            <Box>
                <Button
                    variant="outlined"
                    sx={{ borderWidth: 2, borderColor: '#2f6bf0', color: '#2f6bf0', fontWeight: 'bold', fontSize: '20px', width: '326px', borderRadius: 3, padding: '10px', marginBottom: 2 }}
                    onClick={() => {
                        if (agreePrivacy) {
                            setCompanyType("법인");
                            window.location.href = 'https://kauth.kakao.com/oauth/authorize?client_id=3594f96dc79063c265a9325eda7b8988&redirect_uri=https://tax24app.com/survey&response_type=code';
                        } else {
                            alert('개인정보 수집 이용 동의를 체크해주세요.')
                        }
                    }}
                >
                    법인사업자 지원받기
                </Button>
            </Box>
            <Box>
                <Button
                    variant="outlined"
                    sx={{ borderWidth: 2, borderColor: '#2f6bf0', color: '#2f6bf0', fontWeight: 'bold', fontSize: '20px', width: '326px', borderRadius: 3, padding: '10px', marginBottom: 2 }}
                    onClick={() => {
                        if (agreePrivacy) {
                            setCompanyType("개인");
                            window.location.href = 'https://kauth.kakao.com/oauth/authorize?client_id=3594f96dc79063c265a9325eda7b8988&redirect_uri=https://tax24app.com/survey&response_type=code';
                        } else {
                            alert('개인정보 수집 이용 동의를 체크해주세요.')
                        }
                    }}
                >
                    개인사업자 지원받기
                </Button>
            </Box>
            <Box>
                <Button
                    variant="outlined"
                    sx={{ borderWidth: 2, borderColor: '#2f6bf0', color: '#2f6bf0', fontWeight: 'bold', fontSize: '20px', width: '326px', borderRadius: 3, padding: '10px', marginBottom: 2 }}
                    onClick={() => alert('서비스 준비중입니다.')}
                >
                    세금 환급받기
                </Button>
            </Box>
            {/* <Box>
                <Button
                    variant="outlined"
                    sx={{ borderWidth: 2, borderColor: '#2f6bf0', color: '#2f6bf0', fontWeight: 'bold', fontSize: '20px', width: '326px', borderRadius: 3, padding: '10px', marginBottom: 2 }}
                    onClick={() => alert('서비스 준비중입니다.')}
                >
                    근로자/프리랜서 지원받기
                </Button>
            </Box> */}
            <br /><br /><br />
            <FormGroup>
                <FormControlLabel sx={{ justifyContent: 'center' }} control={<Checkbox value={agreePrivacy} onChange={changeAgreePrivacy} />} label="개인정보 수집 이용 동의(필수)" />
            </FormGroup>

        </Container>
    </ >
}

export default SelectEntity;