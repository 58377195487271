import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { SurveyAction } from './types';
import { getSurveyList, IPostSurvey, postSurvey } from '../../service/Survey';
import { getSurveyListAsync, postSurveyAsync } from './actions';

export function getSurveyListThunk(): ThunkAction<void, RootState, null, SurveyAction> {
    return async dispatch => {
        const { request, success, failure } = getSurveyListAsync;
        dispatch(request());
        try {
            const surveyList = await getSurveyList();
            dispatch(success(surveyList));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};


export function postSurveyThunk(surveyValue: IPostSurvey): ThunkAction<void, RootState, null, SurveyAction> {
    console.log("postSurveyThunk 썽크 내부 테스트", surveyValue)
    return async dispatch => {
        const { request, success, failure } = postSurveyAsync;
        dispatch(request());
        try {
            const survey = await postSurvey(surveyValue);
            dispatch(success(survey));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};
